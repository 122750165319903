<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mt-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- Add an existing domain (requires verification) -->
                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar short flat :color="primaryColor" dark>
                            <v-toolbar-title>Manage a registered domain</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>You will need to verify ownership of the domain before it is added to your account.</p>
                            <p>To get started, enter the domain name below.</p>
                        </v-card-text>
                        <v-card-text class="px-5 pt-0">
                        <v-form @submit.prevent="submitForm" onSubmit="return false;" @keyup.enter.prevent="submitForm">
                            <v-text-field
                                ref="newDomainNameRef"
                                v-model="newDomainName"
                                label="Domain Name"
                                :color="primaryColor"
                                required
                                hint="The name for the domain"
                                type="text"
                                outlined
                                :error-messages="newDomainNameErrorMessage"
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" :style="primaryButtonStyle" @click="submitForm" :disabled="!isAddFormComplete">
                                <span>Continue</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
import { isValidDomainName } from '@/sdk/input';

const ERROR_DISPLAY_DURATION = toMillis({ seconds: 15 });

export default {
    data: () => ({
        account: null,
        error: null,
        newDomainName: null,
        newDomainNameErrorMessage: null,
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isAddFormComplete() {
            // TODO: check that domain name is not too similar to existing domain name
            return typeof this.newDomainName === 'string' && this.newDomainName.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).self.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async submitForm() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            // check that domain name is filled in
            if (typeof this.newDomainName !== 'string' || this.newDomainName.trim().length === 0) {
                this.newDomainNameErrorMessage = 'Domain name is required';
                setTimeout(() => { this.newDomainNameErrorMessage = null; }, ERROR_DISPLAY_DURATION);
                return;
            }
            // check that it's a valid domain name
            if (!isValidDomainName(this.newDomainName)) {
                this.newDomainNameErrorMessage = 'Valid domain name is required';
                setTimeout(() => { this.newDomainNameErrorMessage = null; }, ERROR_DISPLAY_DURATION);
                return;
            }
            this.createDomain();
        },
        async createDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.newDomainName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    case 'DOMAIN_ALREADY_CLAIMED':
                        this.$router.push({ name: 'account-dispute-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Unknown error' });
                        break;
                    }
                }
                if (isCreated) {
                    this.$router.push({ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                }
            } catch (err) {
                console.error('failed to create domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
        cancel() {
            this.$router.go(-1);
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    mounted() {
        this.loadAccount();
        if (this.$route.query.domain) {
            this.newDomainName = this.$route.query.domain;
        }
        this.$nextTick(() => {
            setTimeout(() => { this.activate('newDomainNameRef'); }, 1);
        });
    },
};
</script>
